<template>
  <div class="d-flex flex-grow-1 justify-space-between">
    <div class="d-flex justify-end align-center">
      <slot name="tools-left"></slot>
    </div>
    <div class="d-flex justify-end align-center">
      <slot name="tools-right"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    headingOverride: String,
  },
  data() {
    return {
      metaTitle: null,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        this.metaTitle = to.meta.title;
      },
    },
  },
  computed: {
    heading() {
      return this.headingOverride || this.metaTitle;
    },
  },
};
</script>

<style lang="scss" scoped></style>
